import React from "react"

export default (
<svg 
    xmlns="http://www.w3.org/2000/svg" 
    x="0px" 
    y="0px"
	width="32px" 
    height="32px" 
    viewBox="0 0 64 64" 
>
<g>
	<g>
		<g id="circle_4_">
			<g>
				<path d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32s32-14.327,32-32S49.673,0,32,0z M32,62C15.432,62,2,48.568,2,32.001
					C2,15.432,15.432,2,32,2c16.568,0,30,13.432,30,30.001C62,48.568,48.568,62,32,62z"/>
			</g>
		</g>
		<g id="mail">
			<g>
				<path d="M46,21H18c-1.104,0-2,0.896-2,2v18c0,1.104,0.896,2,2,2h28c1.104,0,2-0.896,2-2V23C48,21.896,47.104,21,46,21z
					 M44.205,23L32,35L19.891,23H44.205z M18,41V24.006l9.589,9.589L20.061,41H18z M22.985,41l6.081-5.927l1.58,1.579
					c0.781,0.781,2.047,0.781,2.829,0l1.531-1.531L41.087,41H22.985z M46,41h-1.939l-7.527-7.405L46,24.128V41z"/>
			</g>
		</g>
	</g>
</g>
</svg>
)




