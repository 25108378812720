import React from "react"
import { PageBody, Column, Row } from "../theme"

const Loading = () => {
    return(
        <PageBody>
            <Row>
                <h1>Page is loading</h1>
            </Row>
        </PageBody>
    )
}

export default Loading