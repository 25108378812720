import React, {useEffect, useState} from "react"
import { PageBody, Column, Row } from "../theme"

import { collection, getDocs } from "firebase/firestore"

const Arcade = () => {
    return(
        <PageBody>
            <Row>
                <h1>WIP!</h1>
            </Row>
        </PageBody>
    )
}

export default Arcade