import React from "react"

const NotFound = () => {
    return(
        <div>
            <p>I am a 404</p>
        </div>
    )
}

export default NotFound